<template>
    <div class="content-wrap">
        <div class="items">
            <div
                v-for="item in list"
                :class="{ 'list-cell': true, actived: item.selected }"
                @click="handleRowClick(item)"
                :key="item.id">
                <div>
                    <div class="topic-title" v-html="item.content"></div>
                    <div class="topic-content" v-html="item.content"></div>
                    <div class="topic-content"> 品牌：{{ item.brandName }} &nbsp;&nbsp;&nbsp;车系：{{ item.seriesName }}
                    </div>
                </div>

                <div>
                    <Icon type="ios-arrow-forward" class="icon" />
                </div>
            </div>
        </div>
        <Page
            :total="pagingData.total"
            :current="pagingData.page"
            :pageSize="pagingData.size"
            :pageSizeOpts="pagingData.pageSizeOpts"
            @on-change="gotoPage"
            @on-page-size-change="pageSizeOnChange"
            show-sizer
            show-elevator
            show-total />
    </div>
</template>

<script>
import { request } from '@/network/request';
import { isEmpty } from '@/common/utils';

export default {
    name: 'manual',
    data() {
        return {
            list: [],
            pagingData: {
                page: 1,
                size: 10,
                total: 0,
                pageSizeOpts: [10, 20, 50, 100]
            }
        };
    },
    props: {
        keyword: {
            type: String,
            default: ''
        }
    },
    watch: {
        keyword(val) {
            this.keyword = val
        }
    },
    created() {
        this.loadData();
    },
    mounted() { },
    methods: {
        loadData() {
            const url = `/manual/doc/content-search/kw`;
            const { page, size } = this.pagingData;
            const params = this.getPageQueryParams({ type: '1', kw: this.keyword }, page, size);

            this.showLoading = true;

            request
                .get(url, params)
                .then(data => {
                    if (data.success) {
                        this.pagingData.total = data.result.totalCount;
                        this.list = this.getHignlight(data);
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {
                    this.showLoading = false;
                });
        },

        getHignlight(data) {
            let list = data.result.data || [];
            list.forEach(item => {
                if (item.title) {
                    item.title = this.highlightNode(item.title, this.keyword);
                }
                item.content = this.highlightNode(item.content, this.keyword);
            });

            return list;
        },

        // 高亮节点
        highlightNode(title, keyword) {
            if (!isEmpty(keyword)) {
                const regexp = new RegExp(keyword, 'g');
                return title.replace(regexp, `<span style="color:#616DF1;">${keyword}</span>`);
            } else {
                return title;
            }
        },

        handleRowClick(item) {
            this.list.map(item => (item.selected = false));
            this.$set(item, 'selected', true);

            let routeData = this.$router.resolve({
                path: '/serviceManual/discription',
                query: {
                    id: item.docId,
                    manualFileType: 'pdf'
                }
            });
            window.open(routeData.href, '_blank');
        },

        gotoPage(page) {
            this.pagingData.page = page;
            this.loadData();
        },

        pageSizeOnChange(size) {
            this.pagingData.page = 1;
            this.pagingData.size = size;
            this.loadData();
        }
    }
};
</script>

<style lang="less" scoped>
.content-wrap {
    .list-cell {
        display: flex;
        cursor: pointer;
        // flex-direction: column;
        justify-content: space-between;
        border-bottom: 1px dotted @border-color;
        padding: 5px;

        .topic-title {
            font-weight: 700;
        }

        .topic-content {
            /deep/ span {
                font-weight: 700;
                color: @primary-color;
            }
        }

        .icon {
            margin-top: 10px;
            font-size: 20px;
        }

        &:hover {
            background: @light-primary-color;
        }

        &.actived {
            background: #dfe2fa;
        }
    }

    .ivu-page {
        margin-top: 20px;
        float: right;
    }
}
</style>
