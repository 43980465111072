<template>
    <div class="content-wrap">
        <div class="main">
            <Input class="input" size="large" search clearable enter-button v-model="keyword" @on-search="onSearch" />
            <Tabs type="card">
                <!-- 手册资料 -->
                <TabPane label="手册资料" name="manual">
                    <manual ref="manual" :keyword="keyword" />
                </TabPane>

                <!-- 工时 -->
                <TabPane label="工时" name="laborTime">
                    <laborTime ref="laborTime" :keyword="keyword" />
                </TabPane>

                <!-- 视频资料 -->
                <TabPane label="视频资料" name="videos">
                    <videos ref="videos" :keyword="keyword" />
                </TabPane>

                <!-- 技术通讯 -->
                <TabPane label="技术通讯" name="communication">
                    <communication ref="communication" :keyword="keyword" />
                </TabPane>
            </Tabs>
        </div>
    </div>
</template>

<script>
import manual from './manual.vue';
import laborTime from './laborTime.vue';
import videos from './videos.vue';
import communication from './communication.vue';

export default {
    name: 'mixedQuery',
    components: {
        manual,
        laborTime,
        videos,
        communication
    },
    data() {
        return {
            keyword: ''
        };
    },
    watch: {},
    methods: {
        onSearch() {
            this.$refs.manual.loadData()
            this.$refs.laborTime.loadData()
            this.$refs.videos.loadData()
            this.$refs.communication.loadData()
        }
    }
};
</script>

<style lang="less" scoped>
.content-wrap {
    width: 100%;
    height: 100%;
    background-color: #ffffff;

    .main {
        padding: 0 300px;
        height: 100%;

        .input {
            width: 400px;
            padding: 20px 0;
        }
    }
}
</style>
